import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import values from 'lodash/fp/values';
import isEmpty from 'lodash/fp/isEmpty';
import { Button } from '@sumup/circuit-ui';
import Tagger from '@elbwalker/tagger';

import RichText from '../../RichText';

import { BUTTON_TYPES, CUSTOM_PRIMARY_COLOR_OPTS } from './constants';

import useFeatureFlag from '~/shared/hooks/use-feature-flag';
import * as inline from '~/shared/components/RichText/configs/inline';
import SiteContext from '~/shared/providers/SiteContext';
import dataSelector from '~/shared/util/data-selector';
import { trackingContentEntryPropType } from '~/shared/util/shared-prop-types';
import Link from '~/shared/components/Link';
import * as SalesforceLiveagent from '~/shared/services/salesforce-liveagent';
import { LIVEAGENT_ENABLE_FEATURE_FLAG } from '~/shared/constants/salesforce-liveagent';
import useActiveCookieCategories from '~/shared/hooks/use-active-cookie-categories';
import * as OneTrust from '~/shared/services/onetrust';
import { AddToCart } from '~/domains/shop/components/AddToCart';
import useOptimizelyData from '~/shared/services/optimizely/use-optimizely-data';
import { hasShopIntegration } from '~/domains/shop/util/has-shop-integration';

const buttonInlineStyles = ({ theme, isInline }) =>
  isInline &&
  css`
    margin-top: ${theme.spacings.mega};
    width: 100%;
    display: inline-block;

    ${theme.mq.kilo} {
      margin-right: ${theme.spacings.byte};
      flex-basis: auto;
      width: auto;
    }
  `;

const buttonStyles = ({ theme }) => css`
  text-align: center;
  display: inline-block;

  ${theme.mq.mega} {
    width: auto;
  }
`;

const customPrimaryStyles = ({ customPrimaryColor, variant }) =>
  // NOTE: purple button "color" is forced into "var(--cui-fg-on-strong)" values
  // of light mode regardless the actual mode by design decision
  variant === 'primary' &&
  customPrimaryColor === CUSTOM_PRIMARY_COLOR_OPTS.PURPLE &&
  css`
    color: #fff; /* taken from var(--cui-fg-on-strong) of light mode */
    background-color: var(--cui-bg-promo-strong);
    border-color: var(--cui-border-promo);
    &:hover {
      background-color: var(--cui-bg-promo-strong-hovered);
      border-color: var(--cui-border-promo-hovered);
      color: #fff; /* taken from var(--cui-fg-on-strong-hovered) of light mode */
    }
    &:active {
      background-color: var(--cui-bg-promo-strong-pressed);
      border-color: var(--cui-border-promo-pressed);
      color: #fff; /* taken from var(--cui-fg-on-strong-pressed) of light mode */
    }
  `;

const fullWidthButtonStyles = ({ fullWidthButtons }) =>
  fullWidthButtons &&
  css`
    display: block;
    width: 100% !important;
  `;

const soloButtonBaseStyles = ({ theme, soloDesign = {} }) =>
  soloDesign &&
  css`
    ${soloDesign.enableRoundedDesign &&
    css`
      padding-top: ${theme.spacings.mega};
      padding-bottom: ${theme.spacings.mega};
      width: auto;
      min-width: 196px;
      border-radius: ${theme.borderRadius.exa};
      ${theme.mq.kilo} {
        margin-right: 0;
      }
    `}

    ${theme.mq.kilo} {
      ${soloDesign.spacingTop &&
      css`
        margin-top: ${soloDesign.spacingTop};
      `}
      ${soloDesign.spacingBottom &&
      css`
        margin-bottom: ${soloDesign.spacingBottom};
      `}
    }

    ${theme.mq.untilKilo} {
      ${soloDesign.spacingTopMobile &&
      css`
        margin-top: ${soloDesign.spacingTopMobile};
      `}
      ${soloDesign.spacingBottomMobile &&
      css`
        margin-bottom: ${soloDesign.spacingBottomMobile};
      `}
    }
  `;
const soloButtonSecondaryStyles = ({ theme, soloDesign = {}, variant }) =>
  variant !== 'primary' &&
  soloDesign &&
  soloDesign.enableRoundedDesign &&
  css`
    color: var(--cui-fg-accent);
    border-color: var(--cui-border-accent);
    border-width: ${theme.borderWidth.mega};
    &:hover {
      border-color: var(--cui-border-accent);
    }
  `;

const alignToAllButtonsStyles = ({ isAlignedToAllButtons }) =>
  isAlignedToAllButtons &&
  css`
    margin-top: auto;
  `;

const StyledButton = styled(Button)(
  buttonStyles,
  customPrimaryStyles,
  fullWidthButtonStyles,
  buttonInlineStyles,
  alignToAllButtonsStyles,
  soloButtonBaseStyles,
  soloButtonSecondaryStyles,
);

export function getVariant(primary) {
  return primary ? 'primary' : 'secondary';
}

/**
 * Embed a button into rich text.
 */
function EmbeddedButton({
  label,
  url,
  trackingId,
  optimizelyFullStackClickEvents,
  primary = true,
  customPrimaryColor,
  isInline = false,
  fullWidthButtons = false,
  buttonType = BUTTON_TYPES.LINK,
  trackingContentEntry = {},
  soloDesign = {},
  taggingAction,
  taggingTrigger,
  isAlignedToAllButtons = false,
  product,
}) {
  const site = useContext(SiteContext);
  const enableSalesforceLiveagent = useFeatureFlag(
    LIVEAGENT_ENABLE_FEATURE_FLAG,
  );

  const activeCookieCategories = useActiveCookieCategories();
  const { featureToggles } = useOptimizelyData();
  const shopIntegration = hasShopIntegration(featureToggles);

  const { locale } = site;
  const isChatButton = buttonType === BUTTON_TYPES.CHAT;
  const isSalesforceChat = !!SalesforceLiveagent.getLocaleId(locale);
  const isCookieSettingsButton = buttonType === BUTTON_TYPES.COOKIE_SETTINGS;

  const tagger = React.useMemo(() => {
    if (!taggingAction || !taggingTrigger) {
      return {};
    }
    const t = Tagger();

    const info = {
      ...t.action(taggingTrigger, taggingAction),
    };

    return info;
  }, [taggingAction, taggingTrigger]);

  const isShopButton = buttonType === BUTTON_TYPES.SHOP;
  const shouldRenderAddToCartButton =
    isShopButton && shopIntegration && product?.skuCode;

  const shouldNotRender =
    // missing label text
    !label ||
    // missing link url, not chat, not cookie settings and not "add to cart"
    (!url &&
      !isChatButton &&
      !isCookieSettingsButton &&
      !shouldRenderAddToCartButton) ||
    // is chat but chat is disabled
    (isChatButton && !enableSalesforceLiveagent) ||
    // is cookie settings but cookie categorization is disabled
    (isCookieSettingsButton && isEmpty(activeCookieCategories));

  if (shouldNotRender) {
    return null;
  }

  const linkUrl = isChatButton ? '' : url;
  const dataSelectorValue = primary ? 'button_primary' : 'button_secondary';

  const handleClick = () => {
    if (isChatButton && isSalesforceChat) {
      SalesforceLiveagent.openChat();
    }

    if (isCookieSettingsButton) {
      OneTrust.openCookieSettingsModal();
    }
  };

  const variant = getVariant(primary);

  if (shouldRenderAddToCartButton) {
    return (
      <AddToCart
        product={product}
        fullWidth={fullWidthButtons}
        isAlignedToAllButtons={isAlignedToAllButtons}
        label={label}
        soloDesign={{}}
        customPrimaryColor={customPrimaryColor}
        variant={variant}
      />
    );
  }

  return (
    <Link
      href={linkUrl}
      trackingId={trackingId}
      optimizelyFullStackClickEvents={optimizelyFullStackClickEvents}
      trackingContentEntry={trackingContentEntry}
      onClick={handleClick}
    >
      <StyledButton
        data-selector={dataSelector(dataSelectorValue, 'richText')}
        isInline={isInline}
        variant={variant}
        customPrimaryColor={customPrimaryColor}
        fullWidthButtons={fullWidthButtons}
        isAlignedToAllButtons={isAlignedToAllButtons}
        soloDesign={soloDesign}
        {...tagger}
      >
        <RichText
          renderNode={inline.createRenderNode(inline.ONE, undefined, {
            text: {
              as: 'strong',
              variant: 'highlight',
              soloDesign,
            },
          })}
          richText={label}
        />
      </StyledButton>
    </Link>
  );
}

EmbeddedButton.propTypes = {
  label: PropTypes.object,
  url: PropTypes.string,
  trackingId: PropTypes.string,
  optimizelyFullStackClickEvents: PropTypes.arrayOf(PropTypes.string),
  primary: PropTypes.bool,
  customPrimaryColor: PropTypes.string,
  isInline: PropTypes.bool,
  fullWidthButtons: PropTypes.bool,
  buttonType: PropTypes.oneOf(values(BUTTON_TYPES)),
  /**
   * Information for analytics tracking event.
   */
  trackingContentEntry: trackingContentEntryPropType,

  /*
   * TEMPORARY EXPERIMENTAL SOLO STYLES EXTENSIONS!
   * added via https://sumupteam.atlassian.net/browse/SA-32606
   * TBD: will be removed or converted to proper features
   */
  soloDesign: PropTypes.object,
  taggingAction: PropTypes.string,
  taggingTrigger: PropTypes.string,
  isAlignedToAllButtons: PropTypes.bool,
};

/**
 * @component
 */
export default EmbeddedButton;
