import React from 'react';
import slugifyExternal from 'slugify';
import get from 'lodash/fp/get';
import isString from 'lodash/fp/isString';
import isArray from 'lodash/fp/isArray';
import isEmpty from 'lodash/fp/isEmpty';

const defaultOptions = {
  lower: true,
  // Strips everything except letters, digits, and whitespace.
  remove: /[^\w\d\s]/g,
};

export default function slugify(children, customOpts = {}) {
  const opts = { ...defaultOptions, ...customOpts };

  if (isString(children)) {
    return slugifyExternal(children, opts);
  }

  if (!isArray(children)) {
    return null;
  }

  const reactElements = children.filter(React.isValidElement);
  const strings = children.filter(isString);

  if (!isEmpty(reactElements)) {
    return slugifyExternal(
      reactElements.map(get('props.children')).join('-'),
      opts,
    );
  }

  // NOTE: React children are often in the shape of an array,
  // even if it is a single string.
  if (!isEmpty(strings)) {
    return slugifyExternal(strings.join('-'), opts);
  }

  return null;
}
