export const BUTTON_TYPES = {
  LINK: 'Link',
  CHAT: 'Chat',
  COOKIE_SETTINGS: 'OneTrust Cookie Settings',
  SHOP: 'Shop',
};

export const CUSTOM_PRIMARY_COLOR_OPTS = {
  PURPLE: 'purple',
  NONE: 'none',
};
