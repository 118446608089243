import React, { useState, Fragment, useRef } from 'react';
import dynamic from 'next/dynamic';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { elb } from '@elbwalker/walker.js';
import type { ComponentDefinition } from '@contentful/experiences-sdk-react';

import AspectRatio from '../AspectRatio';

import { isYouTube } from './components/YouTube/YouTubeService';
import { isVimeo } from './components/Vimeo/VimeoService';

import LoadingBackground from '~/shared/components/LoadingBackground';
import * as Analytics from '~/shared/services/analytics';
import * as OptimizelyFullStack from '~/shared/services/optimizely/optimizely-browser-client';
import { sendNinetailedEvent } from '~/shared/services/ninetailed/events';

const YouTube = dynamic(
  () =>
    import(
      /* webpackChunkName: 'component-video-YouTube' */ './components/YouTube'
    ),
  {
    ssr: false,
  },
);
const Vimeo = dynamic(
  () =>
    import(
      /* webpackChunkName: 'component-video-Vimeo' */ './components/Vimeo'
    ),
  {
    ssr: false,
  },
);
const Standard = dynamic(
  () =>
    import(
      /* webpackChunkName: 'component-video-Standard' */ './components/Standard'
    ),
  {
    ssr: false,
  },
);

const ASPECT_RATIO_FOUR_BY_THREE = 1.34;

const aspectRatioStyles = () => css`
  max-width: 100%;
  margin: 0 auto;
  background-color: rgba(12, 15, 20, 0.07);
`;

const StyledAspectRatio = styled(AspectRatio)(aspectRatioStyles);

const alignToParentStyles = css`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 0;
  height: 100%;
  width: 100%;
`;

const AlignToParent = styled('div')(alignToParentStyles);

function getVideoComponent(src) {
  if (isVimeo(src)) {
    return Vimeo;
  }

  if (isYouTube(src)) {
    return YouTube;
  }

  return Standard;
}

type Props = {
  /**
   * A descriptive title of the video.
   */
  title?: string;
  /**
   * The video source. The video URL for YouTube and Vimeo videos is
   * automatically constructed, other URLs need to be formatted properly.
   */
  src: string;
  /**
   * Apply custom styles to the video player.
   */
  className?: string;
  /**
   * Allow the video player to go fullscreen.
   */
  allowFullScreen?: boolean;
  /**
   * Automatically start playing the video without user interaction.
   */
  autoplay?: boolean;
  /**
   * Restart the video after it finishes playing. (YouTube & Vimeo only)
   */
  loop?: boolean;
  /**
   * Mute the audio when the video starts playing. (YouTube & Vimeo only)
   */
  mute?: boolean;
  /**
   * The user language used for closed captions and the UI. (YouTube only)
   */
  language?: string;
  /**
   * Hide the video controls. (YouTube only)
   */
  hideControls?: boolean;
  /**
   * Remove the platform branding. (YouTube only)
   */
  removeBranding?: boolean;
  /**
   * Show related videos after the video finishes playing. (YouTube only)
   */
  showRelatedVideos?: boolean;
  /**
   * When video is playing
   */
  onPlay?: (..._args: any) => void;
  /**
   * When video is getting paused
   */
  onPause?: (..._args: any) => void;
  /**
   * When video ended
   */
  onEnd?: (..._args: any) => void;
  /**
   * When video loaded
   */
  onLoad?: (..._args: any) => void;
  /**
   * Is Video Playing
   */
  isPlaying?: boolean;
  /**
   * Is the background transparent?
   */
  transparent: boolean;
  /**
   * Which aspect ratio should the video container have?
   */
  aspectRatio?: number;
  /**
   * Align video dimensions to parent
   */
  alignToParent?: boolean;
  /**
   * Is a carousel slide
   */
  isSlide?: boolean;
  /**
   * An id used for tracking video plays
   */
  trackingId?: string;
  /**
   * The click events from Optimizely Full Stack for tracking video plays
   */
  optimizelyFullStackClickEvents?: string[];

  contentType: string;
  id: string;
  name: string;
};

/**
 * Display a responsive video.
 */
function Video({
  className,
  aspectRatio = ASPECT_RATIO_FOUR_BY_THREE,
  autoplay = false,
  loop = false,
  hideControls = false,
  allowFullScreen = true,
  removeBranding = true,
  showRelatedVideos = false,
  transparent = true,
  isPlaying = false,
  onLoad = () => {},
  onPause = () => {},
  onEnd: _onEnd = (..._args: any[]) => {},
  onPlay: _onPlay = (..._args: any[]) => {},
  isSlide = false,
  alignToParent = false,
  ...rest
}: Props) {
  const {
    src,
    contentType,
    id,
    name,
    trackingId,
    optimizelyFullStackClickEvents,
  } = rest;
  const hasBeenPlayed = useRef(false);

  const [loading, setLoading] = useState(true);

  if (!src) {
    return null;
  }

  const Component = getVideoComponent(src);

  const onPlay = (...args) => {
    const tid = trackingId || 'play';
    Analytics.sendEvent({
      action: tid,
      event: 'interaction',
      target: 'Mkt_Web',
      contentEntryId: id,
      contentEntryName: name,
      contentType,
      destinationCategory: Analytics.destinationCategory.UI_OPERATION,
      destinationUrl: undefined,
    });

    if (!hasBeenPlayed.current) {
      elb('video started', {
        video_id: id,
      });
    } else {
      elb('video relaunched', {
        video_id: id,
      });
    }

    hasBeenPlayed.current = true;

    sendNinetailedEvent(tid);

    OptimizelyFullStack.trackEvents(optimizelyFullStackClickEvents);

    return _onPlay(...args);
  };

  const onEnd = (...args) => {
    elb('video completed', {
      video_id: id,
    });
    return _onEnd(...args);
  };

  const onComponentLoad = () => {
    setLoading(false);
    onLoad();
  };

  const content = (
    <Fragment>
      <LoadingBackground isLoading={loading} />
      <Component
        autoplay={autoplay}
        loop={loop}
        hideControls={hideControls}
        allowFullScreen={allowFullScreen}
        removeBranding={removeBranding}
        showRelatedVideos={showRelatedVideos}
        transparent={transparent}
        isPlaying={isPlaying}
        onPause={onPause}
        onEnd={onEnd}
        onPlay={onPlay}
        isSlide={isSlide}
        {...rest}
        onLoad={onComponentLoad}
      />
    </Fragment>
  );

  return alignToParent ? (
    <AlignToParent data-elbcontext="component:video">{content}</AlignToParent>
  ) : (
    <StyledAspectRatio aspectRatio={aspectRatio} className={className}>
      {content}
    </StyledAspectRatio>
  );
}

/**
 * @component
 */
export default Video;

export const VideoDefinition: ComponentDefinition = {
  id: 'video',
  name: 'Video',
  category: 'Assets',
  thumbnailUrl:
    'https://images.ctfassets.net/baw0wklqktjb/2ot7Yd2e2ZdCXsJEpgbhYs/ffb6ac3d46d31d1abf82bc100e9a0604/youtube-logo.png?h=250',
  builtInStyles: ['cfMargin', 'cfPadding', 'cfHeight'],
  variables: {
    src: {
      type: 'Media',
      displayName: 'URL',
      group: 'content',
      defaultValue: 'https://www.youtube.com/embed/bGMo1XCNQMo',
    },
  },
};
